// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace New {
  export const OhakaRequest = 'ohakaRequest'
  export const OhakaRequestWithTour = 'ohakaRequestWithTour'
  export const OhakaTourRequest = 'ohakaTourRequest'
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Complete {
  export const AdditionalTourRequest = 'additionalTourRequest'
  export const SurveyEntry = 'surveyEntry'
}
